import { useEffect, useState } from 'react';
import {
  ContentGroup,
  WindowComponentForSelectors as WindowComponent,
  WindowComponentTypeNames
} from "@/src/web/regions/types";
import {requestRegionIndexEndpoint} from "@/src/web/regions/utils/requestRegionIndexEndpoint";

type RegionData = {
  contentGroups: ContentGroup[];
  homePath: string | null;
  homePathName: string | null;
  prefecturePath: string | null;
  prefectureName: string | null;
  railLineName: string | null;
  backLinkUrl: string | null;
};

export type FetchWindowComponentProps = {
  type: WindowComponentTypeNames | null;
  url: string | null;
  preLoad?: boolean;
  requestId?: string;
  chainName?: string | null;
}

type FetchRegionDataResult = {
  component: WindowComponent | null;
  error: string | null;
}

const apiCache: { [key: string]: RegionData } = {};

const useFetchWindowComponent = ({ type, url, preLoad = false, requestId = '', chainName = null }: FetchWindowComponentProps): FetchRegionDataResult => {
  const [component, setComponent] = useState<WindowComponent | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const newComponent = buildInitializedComponentByType(type);
      if (!preLoad) setComponent(newComponent);
      setError(null);
      if (newComponent.type === 'region' || newComponent.type === 'zipcode') return;

      try {
        if (url) {
          const response = await fetchDataWithCache(url);
          if (!preLoad) {
            setComponent((prev) => {
              return { ...prev, ...response, fetching: false, requestId };
            });
          }
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
        setError(error.message);
      }
    };

    if (!type) return;
    void fetchData();
  }, [type, url, preLoad, requestId]); // eslint-disable-line

  const buildInitializedComponentByType = (type: WindowComponentTypeNames): WindowComponent => {
    const component: WindowComponent = {
      title: '',
      contentGroups: [] as ContentGroup[],
      type: '',
      fetching: true,
      place: null,
      referComponent: null,
      backLinkUrl: '',
      prefectureName: null,
      prefecturePath: null,
      railLineName: '',
      homePath: null,
      homePathName: null,
      requestId: null,
    };
    switch (type) {
      case 'allRegion':
        return { ...component, title: '全国からさがす', type: 'all_region' };
      case 'area':
        return { ...component, title: 'エリアからさがす', type: 'area', place: 1 };
      case 'city':
        return { ...component, title: '市区町村からさがす', type: 'city', place: 2 };
      case 'rail_line':
        return { ...component, title: '路線・駅からさがす', type: 'rail_line', place: 3 };
      case 'station_group':
        return { ...component, title: '路線・駅からさがす', type: 'station_group', place: 3 };
      case 'region':
        return { ...component, title: '地域からさがす', type: 'region', place: null };
      case 'zipcode':
        return { ...component, title: '郵便番号からさがす', type: 'zipcode', place: null };
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Unknown component type: ${(type)}`);
    }
  }

  const fetchDataWithCache = async (url: string): Promise<RegionData> => {
    if (apiCache[url]) {
      return apiCache[url];
    }
    const response = await requestRegionIndexEndpoint(url);
    const res = {
      contentGroups: response.content_groups,
      backLinkUrl: response.back_link_url,
      homePath: response.home_path,
      homePathName: chainName ? chainName : response.home_path_name,
      prefecturePath: response.prefecture_path,
      prefectureName: response.prefecture_name,
      railLineName: response.rail_line_name,
    }
    apiCache[url] = res;
    return res;
  }

  return { component, error };
};

export default useFetchWindowComponent;
