/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
import axios from "axios";
import {ContentGroup} from "@/src/web/regions/types";

// @see Bargain::FilterShops::SelectRegion::Selector#to_h
export type RegionIndexEndpointResponse = {
  back_link_url: string;
  title: string;
  selector_type: string;
  place: string | null;
  prefecture_name: string | null;
  prefecture_path: string | null;
  home_path: string;
  home_path_name: string;
  rail_line_name: string | null;
  content_groups: ContentGroup[];
}

export const requestRegionIndexEndpoint = async (url: string): Promise<RegionIndexEndpointResponse> => {
  try {
    const response = await axios.get(url);

    if (response.data) {
      return {
        title: response.data.title as string,
        place: response.data.place as string | null,
        selector_type: response.data.selector_type as string,
        rail_line_name: response.data.rail_line_name as string | null,
        content_groups: response.data.content_groups as ContentGroup[],
        back_link_url: response.data.back_link_url as string,
        prefecture_name: response.data.prefecture_name as string | null,
        prefecture_path: response.data.prefecture_path as string | null,
        home_path: response.data.home_path as string,
        home_path_name: response.data.home_path_name as string,
      }
    }
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch region index endpoint');
  }
}
